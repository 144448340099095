import React from 'react'

import Footer from './Footer'

const Header = () => (
  <header id="header">
    <div className="inner">
      <h1>
        <strong>Andy Mortimer</strong>
        <br />
        Senior Developer
      </h1>
    </div>
    <Footer />
  </header>
)

export default Header
