import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Footer = () => (
  <div id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <OutboundLink
            href="https://www.linkedin.com/in/aamortimer/"
            aria-label="Linkedin"
            className="icon fa-linkedin"
          >
            <span className="label">linkedin</span>
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            href="https://github.com/aamortimer"
            aria-label="Github"
            className="icon fa-github"
          >
            <span className="label">Github</span>
          </OutboundLink>
        </li>
      </ul>
    </div>
  </div>
)

export default Footer
